import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Button, Modal, Dropdown, DropdownButton } from 'react-bootstrap';

function AccountMenu({email})
{
    const [free, setFree] = useState(false),
	  [isStaff, setIsStaff] = useState(false),
	  [showToken, setShowToken] = useState(false),
	  [accessCopyMesg, setAccessCopyMesg] = useState('click to copy'),
	  [refreshCopyMesg, setRefreshCopyMesg] = useState('click to copy'),
	  [tokenData, setTokenData] = useState(null),
	  getApiToken = ev => {
	      fetch('/apitoken/')
		  .then(r => r.ok ? r.json() : Promise.reject(r))
		  .then(json => {
		      setTokenData(json)
		      setShowToken(true)
		  })
		  .catch(r => {
		      console.error('error in operation: %O, %O', r.status, r.statusText)
		      r.text().then(text => console.error('detail: %O', text))
		  })
	  },
	  copyClipboard = async what => {
	      if ("clipboard" in navigator) {
		  await navigator.clipboard.writeText(tokenData[what]);
	      } else {
		  document.execCommand("copy", true, tokenData[what]);
	      }
	      switch(what) {
	      case 'access':
		  setAccessCopyMesg('Copied!')
		  break;
	      case 'refresh':
		  setRefreshCopyMesg('Copied!')
		  break;
	      }
	      setTimeout(ev => {
		  setAccessCopyMesg('click to copy');
		  setRefreshCopyMesg('click to copy')
	      }, 5000)
	  }
    useEffect(() => {
	fetch('/userdata/account/')
	    .then(r => r.ok ? r.json() : Promise.reject(r))
	    .then(data => {
		console.log('account data: %O', data);
		if ( ! data.active ) setFree(true)
		else if ( data.plan.name == 'Trial' ) setFree(true)
		setIsStaff(data.appuser.user.is_staff)
	    })
	    .catch(r => {
	    })
    }, [])
    return (
	<>
	    {free && (
		<a href="/user/"
		   className="d-flex me-3 p-2 fs-5 text-warning border-warning border-bottom text-decoration-none">
		    <span className="material-symbols-outlined d-flex align-items-center me-1">
			new_releases
		    </span>
		    <span>Upgrade Now</span>
		</a>
	    )}
	    <Dropdown className="me-4">
		<Dropdown.Toggle as="a"
				 style={{cursor: 'pointer'}}>
		    Account
		</Dropdown.Toggle>
		<Dropdown.Menu>
		    <Dropdown.Header>{email}</Dropdown.Header>
		    <Dropdown.Item href="/user/">My Account</Dropdown.Item>
		    <Dropdown.Item onClick={getApiToken}>Get API Token</Dropdown.Item>
		    <Dropdown.Divider />
		    <Dropdown.Item href="/logout/">Logout</Dropdown.Item>
		</Dropdown.Menu>
	    </Dropdown>
	    {showToken && (
		<Modal show={true} onHide={ev => setShowToken(false)}>
		    <Modal.Header closeButton>
			<Modal.Title>API Token</Modal.Title>
		    </Modal.Header>
		    <Modal.Body>
			<div class="mb-3"
			     onClick={ev => copyClipboard('access')}>
			    <label class="form-label">API Token ({accessCopyMesg})</label>
			    <textarea className="form-control"
				      rows="3"
				      value={tokenData.access}
				      onFocus={ev => copyClipboard('access')}
				      readonly/>
			</div>
			<div class="mb-3"
			     onClick={ev => copyClipboard('refresh')}>
			    <label class="form-label">Refresh Token ({refreshCopyMesg})</label>
			    <textarea className="form-control"
				      rows="3"
				      value={tokenData.refresh}
				      onFocus={ev => copyClipboard('refresh')}
				      readonly/>
			</div>
		    </Modal.Body>
		    <Modal.Footer>
			<Button variant="secondary" onClick={ev => setShowToken(false)}>
			    Close
			</Button>
		    </Modal.Footer>
		</Modal>
	    )}
	</>
    )
}

(function() {
    let el = document.getElementById('accountmenu');
    if ( ! el ) return
    createRoot(el).render(<AccountMenu email={el.getAttribute('data-email')}
				       element={el}/>);
})();
